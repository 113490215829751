<template>
  <div class="home-wrapper">
    <!-- <div v-if="showWarning" class="transcoding-warning">
      This video is being processed, and may not play correctly on all
      devices.
    </div> -->
    <div class="video-wrapper">
      <video
        ref="videoPlayer"
        class="video-js video-player vjs-default-skin vjs-big-play-centered"
        controls
        data-setup='{"aspectRatio":"16:9"}'
        playsinline
        webkit-playsinline
        @timeupdate="handlePrimaryTimeUpdate"
        @ended="handlePrimaryEnded"
      ></video>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

function cdn(src) {
  return src.replace(
    'forum-clouthub.s3.us-west-1.amazonaws.com',
    'chforum.backendcdn.com'
  )
  .replace(
    'forum-clouthub.s3-accelerate.amazonaws.com',
    'chforum.backendcdn.com'
  );
}

export default {
  name: 'Home',
  data() {
    return {
      broadcastChannel: new BroadcastChannel('ch-video-player'),
      playerId: `${Math.random()}`,
      autoplayTimeout: undefined,
      src: cdn(this.$route.query.src),
      poster: this.$route.query.poster,
    };
  },
  computed: {
    contentType() {
      return this.src.includes('.m3u8') ? 'application/x-mpegURL' : 'video/mp4';
    },
  },
  unmounted() {
    this.broadcastChannel.removeEventListener('message', this.onSomePlayerPlay);
    this.broadcastChannel.close();
  },
  async mounted() {
    this.play(!!this.$route.query.autoplay, !!this.$route.query.muted);
    window.addEventListener('message', (evt) => {
      if (evt && evt.data && evt.data.type === 'pause-video') {
        if (this.player) {
          this.player.pause();
        }
      }
    });
    this.cleanTimestamps();
    this.broadcastChannel.addEventListener('message', this.onSomePlayerPlay);
  },
  methods: {
    pause() {
      if (this.player) {
        this.player.pause();
      }
    },
    notifyPlay() {
      this.broadcastChannel.postMessage({
        type: 'ch-video-play',
        playerId: this.playerId,
      });
    },
    onSomePlayerPlay({ data }) {
      if (data.type === 'ch-video-play' && data.playerId !== this.playerId) {
        clearTimeout(this.autoplayTimeout);
        this.pause();
      }
    },
    cleanTimestamps() {
      // Clears any timestamps more than 24 hours old
      Object.keys(localStorage).forEach((lskey) => {
        if (lskey.startsWith('ts:')) {
          const lastActive = JSON.parse(localStorage[lskey]).lastActive;
          const elapsed = Date.now() - lastActive;
          if (elapsed > 1000 * 60 * 60 * 24) {
            localStorage.clear(lskey);
          }
        }
      });
    },
    handlePrimaryTimeUpdate(evt) {
      localStorage['ts:' + this.src] = JSON.stringify({
        timestamp: evt.target.currentTime,
        lastActive: Date.now(),
      });
    },
    handlePrimaryEnded(evt) {
      localStorage.removeItem('ts:' + this.src);
    },
    async play(autoplay, isMuted = false) {
      await this.$nextTick();
      const src = this.src;
      const type = this.contentType;
      const options = {
        fluid: true,
        responsive: true,
        poster: this.poster,
        controls: true,
        textTrackSettings: false,
        muted: isMuted,
        sources: [
          {
            src,
            type,
          },
        ],
      };
      this.player = videojs(this.$refs.videoPlayer, options, async () => {
        if (autoplay) {
          this.autoplayTimeout = setTimeout(() => {
            this.player.volume(0);
            this.player.muted(true);
            this.player.play();
          }, 0);
        }
      });
      if (localStorage['ts:' + this.src] && !this.src.includes('mux')) {
        const seekTo = JSON.parse(localStorage['ts:' + this.src]).timestamp;
        this.player.currentTime(seekTo);
      }
      this.player.on('play', this.notifyPlay);
    },
  },
};
</script>
<style scoped>
/* hide the captions settings item from the captions menu */
.vjs-texttrack-settings {
  display: none;
}

.home-wrapper {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  background-color: black;
}
.video-wrapper {
  flex: 1;
  flex-direction: column;
}
.video-player {
  flex: 1;
}
.video-js {
  width: 100% !important;
  height: 100% !important;
}
.transcoding-warning {
  color: white;
}
</style>